import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import RegistraionClosed from './RegistraionClosed'

const Home = () => {
  return (
    <div>
        <div><Navbar/></div>
        <div><RegistraionClosed/></div>
        <div><Footer/></div>
      
    </div>
  )
}

export default Home

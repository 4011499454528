import './App.css';
// import Footer from './component/Footer';
// import Form from './component/Form';
import {
  BrowserRouter as Router,
  Route,
  Routes, 
} from "react-router-dom";
import Home from './component/Home';

 

function App() {
  return (
    <div className="App">
      
      <Router>
      <Routes>
        <Route element={<Home/>} path=''></Route>
      </Routes>
      </Router>
      {/* <Footer/> */}
      
    </div>
  );
}

export default App;

import React from 'react'


const RegistraionClosed = () => {
  return (
    <div className="relative  h-[80vh] ">
        <div
        className="absolute inset-0 bg-cover  bg-center"
        style={{ backgroundImage: 'url(https://icms2024.in/images/h1.jpg)' }}
      ></div>
        <div className='absolute  z-10 bg-slate-200 bg-opacity-35 h-[80vh] w-full justify-center items-center flex'>
            <div className='shadow-md hover:rounded-lg rounded group hover:shadow-xl transition duration-700 hover:scale-125 hover:bg-slate-400 h-full w-full  lg:max-w-[40vw] bg-white lg:max-h-[40vh] max-h-[42vh] max-w-[80vw]'>
                <div className=' items-center flex flex-col justify-center '>
                    <p className='group-hover:text-white mt-10 mx-5 text-red-400'>**We are sorry that registration is now closed because the conference has reached full capacity.**</p>  
                    <h1 className='mt-12 text-3xl font-bold group-hover:text-white text-red-500 '>Registrations are closed now!!</h1>
                    <p className='mt-12 mx-5 text-red-400 group-hover:text-white'>**Thank you for your effort.**</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RegistraionClosed
